import React from 'react';
import { useTranslation } from 'react-i18next';
import FormSelectInput from './FormSelectInput';

export const units = ['Feet & Inches', 'Centimeters'];

const HeightUnitSelector = ({ control }) => {
  const { t } = useTranslation();

  const options = units.map((value) => ({
    value: value,
    label: value,
  }));

  return (
    <FormSelectInput
      control={control}
      selectOptions={options}
      name="heightUnit"
      placeholder={t('placeholders.selectWeightUnit')}
    />
  );
};

export default HeightUnitSelector;
