import {
  HIT_ASSIGNCLIENTSESSIONS_REQUEST,
  GET_ASSIGNCLIENTSESSIONS_DATA,
  FAILED_ASSIGNCLIENTSESSIONS_DATA,
  SET_SIMPLE_SESSION_DATA,
  FAILED_SIMPLE_SESSION_DATA_REQUEST,
  RESET_SESSION_DATA,
  SET_ALL_CLIENT_SESSION,
  FAILED_ALL_CLIENT_SESSION_REQUEST,
  UPDATE_ASSIGNED_SESSION,
} from '../constants';
import {
  clientAssignedSessionByDate,
  getSessionById,
  getAssignedCleintSessionById,
  getAssignedClientSession,
  updateAssignSession,
} from '../../Service/Api';
import moment from 'moment';
import { toast } from 'react-toastify';
import i18n from '../../i18n.js';

/**
 *
 * @params   userId,  date
 */

export const set_client_session_byDate =
  (clientId, date) => async (dispatch) => {
    dispatch({
      type: HIT_ASSIGNCLIENTSESSIONS_REQUEST,
    });

    try {
      const token = localStorage.getItem('token');
      const { _id } = JSON.parse(localStorage.getItem('auth'));
      const response = await clientAssignedSessionByDate(
        token,
        _id,
        clientId,
        date,
      );
      if (response.statusCode === 200) {
        dispatch({
          type: GET_ASSIGNCLIENTSESSIONS_DATA,
          payload: {
            data: response?.data,
          },
        });
      } else {
        dispatch({
          type: RESET_SESSION_DATA,
          payload: {
            data: null,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: FAILED_ASSIGNCLIENTSESSIONS_DATA,
        payload: {
          error:
            err?.message || err?.error || i18n.t('errors.somethingWentWrong'),
        },
      });
    }
  };

export const set_simple_session = (id) => async (dispatch) => {
  dispatch({
    type: HIT_ASSIGNCLIENTSESSIONS_REQUEST,
  });
  try {
    const token = localStorage.getItem('token');
    const response = await getSessionById(token, id);
    if (response.statusCode === 200) {
      dispatch({
        type: SET_SIMPLE_SESSION_DATA,
        payload: {
          data: response.data,
        },
      });
    } else {
      throw response;
    }
  } catch (err) {
    dispatch({
      type: FAILED_SIMPLE_SESSION_DATA_REQUEST,
      payload: {
        error:
          err?.message ?? err?.error ?? i18n.t('errors.somethingWentWrong'),
      },
    });
  }
};

export const reset_assign_session_data = () => (dispatch) => {
  dispatch({ type: RESET_SESSION_DATA });
};

export const clientSessionById = (id) => async (dispatch) => {
  // alert(id);
  dispatch({
    type: HIT_ASSIGNCLIENTSESSIONS_REQUEST,
  });
  try {
    const token = localStorage.getItem('token');
    const response = await getAssignedCleintSessionById(id, token);

    if (response && response.statusCode === 200) {
      dispatch({
        type: GET_ASSIGNCLIENTSESSIONS_DATA,
        payload: {
          data: response?.data,
        },
      });
    } else {
      throw response;
    }
  } catch (err) {
    dispatch({
      type: FAILED_SIMPLE_SESSION_DATA_REQUEST,
      payload: {
        error:
          err?.message ?? err?.error ?? i18n.t('errors.somethingWentWrong'),
      },
    });
  }
};

export const set_all_client_session = (id) => async (dispatch) => {
  dispatch({
    type: HIT_ASSIGNCLIENTSESSIONS_REQUEST,
  });
  try {
    const token = localStorage.getItem('token');
    const response = await getAssignedClientSession(token, id);
    if (response && response.statusCode === 200) {
      const mappedData = response.data.reduce(
        (result, session) => ({
          ...result,
          [moment.utc(session.date).format('MM DD YYYY').toString()]: session,
        }),
        {},
      );
      dispatch({
        type: SET_ALL_CLIENT_SESSION,
        payload: {
          data: mappedData,
        },
      });
    } else {
      throw response;
    }
  } catch (err) {
    dispatch({
      type: FAILED_ALL_CLIENT_SESSION_REQUEST,
      payload: {
        error:
          err?.message ?? err?.error ?? i18n.t('errors.somethingWentWrong'),
      },
    });
  }
};

export const update_assigned_session = (id, data) => async (dispatch) => {
  dispatch({
    type: HIT_ASSIGNCLIENTSESSIONS_REQUEST,
  });
  try {
    const token = localStorage.getItem('token');
    const response = await updateAssignSession(data, id, token);

    if (response?.statusCode === 200) {
      dispatch({
        type: UPDATE_ASSIGNED_SESSION,
        payload: data,
      });
      toast.success(i18n.t('success.sessionUpdated'));
    } else {
      throw response;
    }
  } catch (err) {
    console.error(err);
    toast.error(i18n.t('errors.somethingWentWrong'));

    dispatch({
      type: FAILED_ALL_CLIENT_SESSION_REQUEST,
      payload: {
        error:
          err?.message ?? err?.error ?? i18n.t('errors.somethingWentWrong'),
      },
    });
  }
};
