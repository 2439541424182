import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { AuthContext } from '../../App';
import {
  getWorkoutDataByDate,
  getWorkoutDataByMonths,
} from '../../Service/Api';
import { capitalizeFirstLetter, queryString } from '../../utils';
import LoaderContext from '../../context/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getClients } from '../../redux/actions/Clients';
import moment from 'moment';
import { setUserExercises } from '../../redux/actions/Exercises';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { QueryBuilder } from '@mui/icons-material';
import { getUsers } from '../../redux/actions/Users';

const useStatistics = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { auth } = useContext(AuthContext);
  const loader = useContext(LoaderContext);

  const dateAggregations = ['Day', 'Month'];

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [startDate, setStartDate] = useState(moment().subtract(3, 'month'));
  const [endDate, setEndDate] = useState(moment());
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [aggregation, setAggregation] = useState(dateAggregations[0]);

  const { t } = useTranslation();
  const clientsData = useSelector((state) =>
    auth.isAdmin ? state.users : state.client,
  );
  const exercisesData = useSelector((state) => state.exercise);

  const isMonthSegmentation = aggregation === 'Month';
  const firstMetricType = data?.[0]?.firstMetric.type;
  const secondMetricType = data?.[0]?.secondMetric.type;
  const show1RMCalc =
    firstMetricType === 'reps' && secondMetricType === 'weight';
  const max1RMCalc =
    show1RMCalc &&
    data.reduce(
      (max, item, index) =>
        max.value < item.oneRMCalcMax
          ? { index, value: item.oneRMCalcMax }
          : max,
      { index: 0, value: 0 },
    );

  const chartOptions = useMemo(
    () => ({
      chart: { color: 'white', backgroundColor: 'transparent' },

      title: { text: '' },

      xAxis: {
        type: 'datetime',
        tickInterval: isMonthSegmentation ? 24 * 3600 * 1000 * 30 : undefined,
        lineColor: 'white',
        labels: {
          style: { color: 'white' },
          formatter() {
            const dateFormat = aggregation === 'Day' ? 'DD MMM' : 'MMM YY';
            return moment(new Date(this.value))
              .add('month', 1)
              .format(dateFormat);
          },
        },
      },

      yAxis: {
        gridLineColor: 'rgba(255, 255, 255, 0.1)',
        lineColor: 'white',
        labels: {
          formatter() {
            return `<span style="color: white">${this.value}</span>`;
          },
        },
      },

      legend: { itemStyle: { color: 'white ' } },

      tooltip: { shared: true, xDateFormat: '%d %B %Y' },

      series: [
        {
          type: 'line',
          name: `Mean ${firstMetricType}`,
          data: data?.map((item) => {
            const yAxis = Number(item.firstMetric.mean);
            const xAxis = new Date(item.date).getTime();
            return [xAxis, Number(yAxis.toFixed(2))];
          }),
          tooltip: {
            valueSuffix: ` ${data?.[0]?.firstMetric.unit || ''}`,
          },
        },
        {
          type: 'line',
          name: `Max ${firstMetricType}`,
          data: data?.map((item) => {
            const yAxis = Number(item.firstMetric.max);
            const xAxis = new Date(item.date).getTime();
            return [xAxis, Number(yAxis.toFixed(2))];
          }),
          tooltip: {
            valueSuffix: ` ${data?.[0]?.firstMetric.unit || ''}`,
          },
        },
        {
          type: 'line',
          name: `Mean ${secondMetricType}`,
          data: data?.map((item) => {
            const yAxis = Number(item.secondMetric.mean);
            const xAxis = new Date(item.date).getTime();
            return [xAxis, Number(yAxis.toFixed(2))];
          }),
          tooltip: {
            valueSuffix: ` ${data?.[0]?.secondMetric.unit || ''}`,
          },
        },
        {
          type: 'line',
          name: `Max ${secondMetricType}`,
          data: data?.map((item) => {
            const yAxis = Number(item.secondMetric.max);
            const xAxis = new Date(item.date).getTime();
            return [xAxis, Number(yAxis.toFixed(2))];
          }),
          tooltip: {
            valueSuffix: ` ${data?.[0]?.secondMetric.unit || ''}`,
          },
        },
        ...(show1RMCalc
          ? [
              {
                type: 'line',
                name: `Mean 1RM Calc`,
                data: data?.map((item) => {
                  const yAxis = Number(item.oneRMCalcMean);
                  const xAxis = new Date(item.date).getTime();
                  return [xAxis, Number(yAxis.toFixed(2))];
                }),
              },
              {
                type: 'line',
                name: `Max 1RM Calc`,
                data: data?.map((item, idx) => {
                  const yAxis = Number(item.oneRMCalcMax);
                  const xAxis = new Date(item.date).getTime();

                  if (max1RMCalc.index === idx) {
                    return {
                      y: yAxis,
                      x: xAxis,
                      marker: {
                        enabled: true,
                        symbol: 'circle',
                        lineColor: '#252735',
                        fillColor: '#FEA41D',
                        lineWidth: 2.5,
                        radius: 9,
                      },
                    };
                  }
                  return [xAxis, Number(yAxis.toFixed(2))];
                }),
              },
            ]
          : []),
      ],
    }),
    [data, firstMetricType, secondMetricType, show1RMCalc, isMonthSegmentation],
  );

  const shouldShowChart = useMemo(
    () => firstMetricType === 'reps' && secondMetricType === 'weight',
    [firstMetricType, secondMetricType],
  );

  const isMetricsInvalid = useMemo(
    () =>
      firstMetricType &&
      secondMetricType &&
      (firstMetricType !== 'reps' || secondMetricType !== 'weight'),
    [firstMetricType, secondMetricType],
  );

  const formatDataValue = (value, metric) => {
    if (metric.type === 'time') {
      const seconds_per_hour = 3600;
      return moment
        .utc(value * 1000)
        .format(value >= seconds_per_hour ? 'HH:mm:ss' : 'mm:ss');
    } else {
      const formattedNum = Number(value.toFixed(2));
      return metric.unit ? `${formattedNum} ${metric.unit}` : formattedNum;
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const isSegmentedByDay = aggregation === 'Day';
      const response = await (isSegmentedByDay
        ? getWorkoutDataByDate
        : getWorkoutDataByMonths)(
        auth.token,
        queryString({
          clientId: selectedClient._id,
          exerciseId: selectedExercise._id,
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate: moment(endDate).format('YYYY-MM-DD'),
        }),
      );

      if (response?.statusCode === 200) {
        setData(response.data);

        setTableData(
          response.data?.map((item) => {
            const date = isMonthSegmentation
              ? moment(item.date).format('MMM YYYY')
              : moment(item.date).format('DD MMM YYYY');
            const firstMetricType = response.data?.[0]?.firstMetric.type;
            const secondMetricType = response.data?.[0]?.secondMetric.type;
            const show1RMCalc =
              firstMetricType === 'reps' && secondMetricType === 'weight';

            const tableData = [
              date,
              formatDataValue(item.firstMetric.mean, item.firstMetric),
              formatDataValue(item.firstMetric.max, item.firstMetric),
              formatDataValue(item.secondMetric.mean, item.secondMetric),
              formatDataValue(item.secondMetric.max, item.secondMetric),
            ];
            if (show1RMCalc) {
              tableData.push(
                formatDataValue(item.oneRMCalcMean, item.secondMetric),
              );
              tableData.push(
                formatDataValue(item.oneRMCalcMax, item.secondMetric),
              );
            }
            return tableData;
          }),
        );
      } else {
        throw new Error(response.message || t('errors.somethingWentWrong'));
      }
    } catch (error) {
      console.error(error);
      toast.error(t('errors.somethingWentWrong'));
    } finally {
      setLoading(false);
    }
  };

  const tableHeaders = [
    'Date',
    `Mean ${capitalizeFirstLetter(firstMetricType)}`,
    `Max ${capitalizeFirstLetter(firstMetricType)}`,
    `Mean ${capitalizeFirstLetter(secondMetricType)}`,
    `Max ${capitalizeFirstLetter(secondMetricType)}`,
    ...(show1RMCalc ? ['Mean 1RM Calc', 'Max 1RM Calc'] : []),
  ];

  useEffect(() => {
    if (auth.isAdmin) {
      dispatch(getUsers({}, null, null));
    } else {
      dispatch(getClients({ coachId: auth._id }));
    }
  }, []);

  useEffect(() => {
    loader.setLoading(loading);
  }, [loader, loading]);

  useEffect(() => {
    if (clientsData?.data?.length) {
      const initialClient = clientsData.data[0];
      setSelectedClient(initialClient);
    }
  }, [clientsData?.data]);

  useEffect(() => {
    if (selectedClient) {
      dispatch(setUserExercises(selectedClient._id));
      setData([]);
      setTableData([]);
    }
  }, [selectedClient]);

  useEffect(() => {
    if (exercisesData?.data?.length && selectedClient?._id) {
      const initialExercise = exercisesData.data[0];
      setSelectedExercise(initialExercise);
    }
  }, [exercisesData?.data]);

  useEffect(() => {
    if (isMonthSegmentation) {
      setStartDate(moment(startDate).startOf('month'));
      const endOfMonth = moment(endDate).endOf('month');
      setEndDate(endOfMonth.diff(moment(), 'days') > 0 ? moment() : endOfMonth);
    }
  }, [aggregation]);

  useEffect(() => {
    if (selectedExercise) fetchData();
  }, [selectedExercise, startDate, endDate, aggregation]);

  useEffect(() => {
    if (clientsData.data?.length) {
      const userId = searchParams.get('userId');
      setSelectedClient(
        clientsData?.data.find((client) => client._id === userId) ||
          clientsData?.data[0],
      );
    }
  }, [clientsData.data]);

  return {
    data,
    chartOptions,
    isMetricsInvalid,
    shouldShowChart,
    yAxisUnitType: firstMetricType,
    tableData,
    tableHeaders,
    selectedClient,
    setSelectedClient,
    clients: clientsData?.data,
    selectedExercise,
    setSelectedExercise,
    exercises: exercisesData?.data,
    endDate,
    startDate,
    setStartDate,
    setEndDate,
    page,
    setPage,
    pageSize,
    setPageSize,
    aggregation,
    setAggregation,
    dateAggregations,
    isMonthSegmentation,
    firstMetricType,
    secondMetricType,
    show1RMCalc,
    max1RMCalc,
    loading,
  };
};

export default useStatistics;
