import { Select } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { allTimezones, useTimezoneSelect } from 'react-timezone-select';
import FormSelectInput from './FormSelectInput';

export const timezones = {
  ...allTimezones,
  'Europe/Berlin': 'Frankfurt',
};

const TimezoneSelector = ({ control }) => {
  const { t } = useTranslation();

  const { options: timezoneOpts } = useTimezoneSelect({
    labelStyle: 'original',
    timezones,
  });

  const options = timezoneOpts.map((opt) => ({
    value: opt.value,
    label: opt.label,
  }));

  return (
    <FormSelectInput
      control={control}
      selectOptions={options}
      name="timezone"
      placeholder={t('placeholders.selectTimezone')}
    />
  );
};

export default TimezoneSelector;
